import { 
  fetchAllContractorsApi, 
  fetchGetByRangeContractorsApi, 
  fetchAppendContractorsApi, 
  fetchUpdateContractorsApi
 } from "network/api/contractorsApi";

export const useContractorsRepository = () => {
  async function fetchAllContractorsService(payload) {
    return fetchAllContractorsApi(payload).then((response) => {
      console.log("Fetch all service | Contractors");
      console.log(response);
      return response;
    });
  }

  async function getByIdContractorsService(position) {
    return fetchGetByRangeContractorsApi(position).then((response) => {
      console.log("Get by id service | Contractors");
      console.log(response);
      return response;
    });
  }

  async function appendContractorsService(payload) {
    return fetchAppendContractorsApi(payload).then((response) => {
      console.log("Append service | Contractors");
      console.log(response);
      return response;
    });
  }

  async function updateContractorsService(payload) {
    return fetchUpdateContractorsApi(payload).then((response) => {
      console.log("Update service | Contractors");
      console.log(response);
      return response;
    });
  }

  return {
    fetchAllContractorsService,
    getByIdContractorsService,
    appendContractorsService,
    updateContractorsService
  };
}